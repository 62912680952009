<template>

  <v-row class="inner-content">
    <v-col>
      <CountriesTable />
    </v-col>
  </v-row>

</template>

<script>
export default {
  name: 'CountriesList',

  components: {
    CountriesTable: () => import('@/components/tables/CountriesTable.vue')
  },
}
</script>
